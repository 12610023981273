<template>
    <span>{{ (date && dayjs(date).isValid()) ? $d(dayjs(date).toDate(), format) : '-' }}</span>
</template>

<script setup>
const props = defineProps({
    date: {
        type: [String, Date, null],
        required: true,
        default: null
    },
    format: {
        type: String,
        default: "dateShort"
    }
})

const dayjs = useDayjs()

</script>

<style scoped>

</style>